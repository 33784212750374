@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Oswald:wght@200..700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Oswald", sans-serif;
}

p,
button,
a,
ul {
  font-family: "Lato", sans-serif;
}

ul {
  list-style: none;
  padding-left: 0;
}

a {
  text-decoration: none;
}

body .popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: -3px -1px 20px 10000px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 500px;
  max-width: 550px;
  height: 300px;
  border-radius: 20px;
  z-index: 9999;
}
body .popup-content {
  font-size: 25px;
  text-align: center;
}
body .ctaBtn {
  border: none;
  padding: 7px 20px;
  border-radius: 5px;
  font-weight: 500;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
body .ctaBtn:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #7e9b78;
  z-index: -2;
}
body .ctaBtn:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: rgba(217, 217, 217, 0.248);
  transition: all 0.5s;
  z-index: -1;
}
body .ctaBtn:hover {
  color: #fff;
}
body .ctaBtn:hover:before {
  width: 100%;
}
body .header {
  padding: 20px 0;
  width: 100%;
}
body .header .header_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
body .header .header_content .logo {
  width: 60px;
  cursor: pointer;
}
body .header .header_content .logo img {
  width: 100%;
}
body .header .header_content .headerBtn {
  border-radius: 30px;
  padding: 7px 38px !important;
  background-color: #7e9b78;
  color: white;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
body .header .header_content .nav_links .nav_list {
  list-style: none;
  display: flex;
  margin-bottom: 0;
  padding-left: 0;
}
body .header .header_content .nav_links .nav_list .nav_link {
  text-decoration: none;
  color: #000;
}
body .header .header_content .nav_links .nav_list .nav_link li {
  margin: 0 15px;
}
body .header .header_content .nav_links .nav_list .nav_link li:hover {
  opacity: 0.8;
}
body .header .header_content .nav_cta .mobile_menu {
  display: none;
  margin-left: 10px;
}
body .header .header_content .nav_cta .mobile_menu img {
  width: 32px;
}
body .contactUs_page .hero_section {
  height: 60vh !important;
}
body .contactUs_page .hero_section .hero_section_wrapper {
  height: 30vh !important;
}
body .contactUs_page .contactUs_form {
  margin: 150px 50px;
}
body .contactUs_page .contactUs_form .form_wrapper {
  margin: auto;
  max-width: 450px;
  background-color: white;
  box-shadow: -2px -1px 4px 0px rgba(122, 121, 123, 0.75);
  -webkit-box-shadow: -2px -1px 4px 0px rgba(122, 121, 123, 0.75);
  -moz-box-shadow: -2px -1px 4px 0px rgba(122, 121, 123, 0.75);
  border-radius: 12px;
  padding: 40px 29px;
}
body .contactUs_page .contactUs_form .form_wrapper .form {
  margin-top: 30px;
}
body .contactUs_page .contactUs_form .form_wrapper .contactus_form_title {
  color: #7e9b78;
  font-size: 36px;
  margin-bottom: 20px;
}
body .contactUs_page .contactUs_form .form_wrapper .submitBtn {
  width: 100%;
  background-color: #7e9b78;
  color: white;
}
body .contactUs_page .contactUs_form .contactUs_info {
  margin: auto;
  max-width: 500px;
  padding: 40px 29px;
}
body .contactUs_page .contactUs_form .contactUs_info .contactusInfo_title {
  margin-bottom: 30px;
  font-size: 36px;
}
body .contactUs_page .contactUs_form .contactUs_info .chating_option {
  margin-bottom: 30px;
}
body .contactUs_page .contactUs_form .contactUs_info .chating_option .social_icon {
  max-width: 35px;
}
body .contactUs_page .contactUs_form .contactUs_info .chating_option .social_icon:hover {
  opacity: 0.8;
}
body .contactUs_page .contactUs_form .contactUs_info .chating_option .social_info {
  color: #252c34;
  margin-left: 10px;
  font-size: 18px;
}
body .contactUs_page .contactUs_form .contactUs_info .chating_option .social_info:hover {
  opacity: 0.8;
}
body #home_page .colored_word {
  color: #7e9b78;
}
body #home_page .hero_section {
  background: url("..//assets//images//hero-grid2.svg");
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 40px;
  height: 80vh;
}
body #home_page .hero_section .hero_section_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
}
body #home_page .hero_section .hero_section_wrapper .hero_content {
  text-align: center;
  margin: auto;
  max-width: 800px;
}
body #home_page .hero_section .hero_section_wrapper .hero_content .hero-title {
  font-size: 45px;
  max-width: 590px;
  margin: 15px auto 25px;
  position: relative;
}
body #home_page .hero_section .hero_section_wrapper .hero_content .hero-title .underlined_word {
  z-index: 1;
  width: 100%;
  max-width: none;
  display: inline-block;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 23%;
  right: 0%;
  transform: translateY(25%);
  padding-top: 30px;
}
body #home_page .hero_section .hero_section_wrapper .hero_content .hero-title .underlined_word img {
  width: 210px;
}
body #home_page .hero_section .hero_section_wrapper .hero_content .hero_description {
  line-height: 1.3;
  max-width: 720px;
  font-size: 18px;
  margin: 5px auto 35px;
}
body #home_page .hero_section .hero_section_wrapper .hero_content .heroBtn {
  background-color: #7e9b78;
  color: white;
  font-size: 17px;
  padding: 8px 39px;
}
body #home_page .introductory_vid {
  margin-bottom: 50px;
}
body #home_page .introductory_vid .video_thumbnail {
  position: relative;
}
body #home_page .introductory_vid .video_thumbnail .thumbnail {
  width: 100%;
  background: url("..//assets/images/playIcon.png");
  background-repeat: no-repeat;
  background-position: center;
}
body #home_page .introductory_vid .video_thumbnail .thumbnail img {
  height: 585px;
  border-radius: 10px;
  position: relative;
  width: 100%;
}
body #home_page .introductory_vid .video_thumbnail .video-icon {
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 8;
  padding: 20px;
  opacity: 1;
}
body #home_page .introductory_vid .video_thumbnail .video-icon img {
  width: 81px;
}
body #home_page .introductory_vid .video_thumbnail .waves-block {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
body #home_page .ourstory_section {
  margin-top: 80px;
}
body #home_page .ourstory_section .ourstory_header {
  margin: auto;
  text-align: center;
  max-width: 300px;
  margin-bottom: 40px;
}
body #home_page .ourstory_section .ourstory_header .section_title {
  font-size: 37px;
}
body #home_page .ourstory_section .ourstory_header .subtitle {
  margin-bottom: 0;
}
body #home_page .ourstory_section .ourstory_header img {
  width: 90%;
}
body #home_page .ourstory_section .ourstory_content .content_title {
  margin-bottom: 30px;
}
body #home_page .ourstory_section .ourstory_content .ourstory_list {
  margin-top: 20px;
}
body #home_page .ourstory_section .ourstory_content .ourstory_list li {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
body #home_page .ourstory_section .ourstory_content .ourstory_list li p {
  padding-left: 6px;
  margin-bottom: 0;
}
body #home_page .ourstory_section .ourstory_img {
  width: 100%;
  margin-top: 40px;
}
body #home_page .ourstory_section .ourstory_img img {
  width: 100%;
  border-radius: 10px;
}
body #home_page .aboutUs_section {
  margin-top: 80px;
  padding: 50px 0;
  background-color: #e2e7f0;
}
body #home_page .aboutUs_section .aboutUs_header {
  text-align: center;
  max-width: 400px;
  margin: 10px auto 60px;
}
body #home_page .aboutUs_section .aboutUs_header .content_title {
  font-size: 37px;
}
body #home_page .aboutUs_section .aboutUs_header img {
  width: 90%;
}
body #home_page .aboutUs_section .aboutImg {
  margin-bottom: 15px;
  width: 300px;
}
body #home_page .aboutUs_section .aboutImg.aboutimg1 {
  background-image: url("..//assets//images//IMG_9653.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 250px;
  border-radius: 12px;
}
body #home_page .aboutUs_section .aboutImg.aboutimg2 {
  background-image: url("..//assets//images//library6.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 250px;
  border-radius: 12px;
}
body #home_page .aboutUs_section .aboutImg.aboutimg3 {
  background-image: url("..//assets//images//library1.jpeg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  width: 300px;
  height: 250px;
  border-radius: 12px;
}
body #home_page .aboutUs_section .aboutImg img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
body #home_page .aboutUs_section .about_description {
  margin-top: 20px;
}
body #home_page .aboutUs_section .about_description .about_subtitle {
  margin-bottom: 0;
}
body #home_page .aboutUs_section .about_description .about_description_title {
  margin-bottom: 20px;
}
body #home_page .aboutUs_section .about_description .about_description_paragraph {
  line-height: 1.3;
}
body #home_page .line {
  text-align: center;
  margin: 30px auto 50px;
}
body #home_page .question_section {
  background: url("..//assets//images//grid2.svg"), #7e9b78;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 90vh;
}
body #home_page .question_section .question_div .question {
  color: white;
  font-size: 60px;
  text-align: center;
  margin: 0 auto 70px;
  max-width: 729px;
}
body #home_page .question_section .books3d_img {
  position: absolute;
  top: 416px;
}
body #home_page .question_section .books3d_img img {
  max-width: 690px;
}
body #home_page .packages_section {
  margin-top: 153px;
  margin-bottom: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
body #home_page .packages_section .packages_header {
  text-align: center;
  max-width: 400px;
  margin: 10px auto 70px;
}
body #home_page .packages_section .packages_header .content-title {
  font-size: 37px;
}
body #home_page .packages_section .packages_header .subtitle {
  margin-bottom: 0;
}
body #home_page .packages_section .packages_header img {
  width: 90%;
}
body #home_page .packages_section .package_card_wrapper {
  max-width: 330px;
  margin: 5px auto 10px;
  border-radius: 12px;
  padding: 25px 20px;
  box-shadow: -2px -1px 4px 0px rgba(122, 121, 123, 0.75);
  -webkit-box-shadow: -2px -1px 4px 0px rgba(122, 121, 123, 0.75);
  -moz-box-shadow: -2px -1px 4px 0px rgba(122, 121, 123, 0.75);
}
body #home_page .packages_section .package_card_wrapper.reader {
  margin-top: 30px;
  background-color: #e2e7f0;
}
body #home_page .packages_section .package_card_wrapper.reader .popular {
  display: none;
}
body #home_page .packages_section .package_card_wrapper.reader .packages_card_footer .package_btn {
  background-color: #7e9b78 !important;
  color: white;
}
body #home_page .packages_section .package_card_wrapper.bookworm {
  background-color: #7e9b78;
  color: white;
}
body #home_page .packages_section .package_card_wrapper.bookworm .popular {
  background-color: #e2e7f0;
  border-radius: 50px;
  padding: 4px 15px;
  float: right;
}
body #home_page .packages_section .package_card_wrapper.bookworm .popular p {
  color: black;
  margin-bottom: 0 !important;
}
body #home_page .packages_section .package_card_wrapper.bookworm .package_btn {
  color: black;
}
body #home_page .packages_section .package_card_wrapper.bookworm .package_btn:after {
  content: "";
  background-color: #e2e7f0;
}
body #home_page .packages_section .package_card_wrapper.bookworm .package_btn:before {
  content: "";
  background-color: rgba(94, 129, 87, 0.2);
}
body #home_page .packages_section .package_card_wrapper.bookworm .package_btn:hover {
  color: #1f242b;
}
body #home_page .packages_section .package_card_wrapper.bookworm .package_btn:hover:before {
  width: 100%;
}
body #home_page .packages_section .package_card_wrapper .package_card_header .package_price {
  font-size: 19px;
  margin-bottom: 29px;
}
body #home_page .packages_section .package_card_wrapper .package_card_header .package_price .price {
  font-weight: bold;
}
body #home_page .packages_section .package_card_wrapper .packages_card_footer .package_btn {
  margin-top: 10px;
  width: 100% !important;
  text-align: center;
  padding: 9px 20px;
  font-size: 17px;
}
body #home_page .packages_section .package_card_wrapper .package_line {
  height: 1px;
  margin: 17px 0;
  width: 100%;
  background-color: #252c34;
}
body #home_page .features_section {
  background-color: #e2e7f0;
  padding: 60px 0 80px;
}
body #home_page .features_section .content_title {
  position: relative;
  text-align: center;
  max-width: 400px;
  font-size: 37px;
  margin: 10px auto 80px;
}
body #home_page .features_section .content_title .underlined_word {
  z-index: 1;
  width: 100%;
  max-width: none;
  display: inline-block;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 5%;
  right: 0%;
  transform: translateY(25%);
  padding-top: 30px;
}
body #home_page .features_section .content_title .underlined_word img {
  width: 160px;
}
body #home_page .features_section .features {
  background-color: white;
  border-radius: 13px;
  padding: 25px 15px;
  margin-bottom: 10px;
  box-shadow: -2px -1px 4px 0px rgba(122, 121, 123, 0.75);
  -webkit-box-shadow: -2px -1px 4px 0px rgba(122, 121, 123, 0.75);
  -moz-box-shadow: -2px -1px 4px 0px rgba(122, 121, 123, 0.75);
  transition: all 0.4s ease-in-out;
  cursor: pointer;
}
body #home_page .features_section .features:hover {
  box-shadow: 0 0px 2px 2px rgba(0, 0, 0, 0.1), 0 6px 9px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
body #home_page .features_section .features .features_card .features_img {
  width: 85px;
  height: 80px;
  margin-bottom: 20px;
}
body #home_page .features_section .features .features_card .features_img.wifi {
  height: 59px !important;
  width: 73px !important;
}
body #home_page .features_section .features .features_card .features_img img {
  width: 100%;
  height: 100%;
}
body #home_page .features_section .features .features_card .feature_card_content .card_title {
  color: #7e9b78;
  margin-bottom: 15px;
  font-size: 21px;
}
body #home_page .features_section .features .features_card .feature_card_content .card_description {
  line-height: 1.3;
}
body #home_page .howtohelp_section {
  padding: 100px 0;
}
body #home_page .howtohelp_section .left_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
body #home_page .howtohelp_section .left_section .left_title {
  margin-bottom: 45px;
  position: relative;
  font-size: 40px;
  max-width: 400px;
}
body #home_page .howtohelp_section .left_section .left_title .underlined_word {
  z-index: 1;
  width: 100%;
  max-width: none;
  display: inline-block;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
  transform: translateY(25%);
  padding-top: 34px;
}
body #home_page .howtohelp_section .left_section .left_title .underlined_word img {
  width: 221px;
}
body #home_page .howtohelp_section .left_section .left_description {
  max-width: 400px;
  font-size: 18px;
  margin-bottom: 35px;
}
body #home_page .howtohelp_section .left_section .donateBtn {
  background-color: #7e9b78;
  color: white;
}
body #home_page .howtohelp_section .right_section .right_title {
  font-size: 23px;
  margin-bottom: 35px;
}
body #home_page .howtohelp_section .right_section .right_list {
  margin-top: 25px;
}
body #home_page .howtohelp_section .right_section .right_list li {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
body #home_page .howtohelp_section .right_section .right_list li p {
  font-size: 16px;
  margin-left: 10px;
  margin-bottom: 0 !important;
}
body #home_page .howtohelp_section .right_section .right_note {
  max-width: 430px;
  margin-top: 38px;
  font-weight: bold;
}
body #home_page .howtohelp_section .right_section .right_note .red {
  color: red;
}
body #home_page .two_lines {
  text-align: center;
}
body #home_page .two_lines img {
  width: 90%;
  margin: auto;
}
body #home_page .faq_section {
  padding: 70px 0;
}
body #home_page .faq_section .faq_content {
  text-align: center;
  color: #252c34;
  margin-bottom: 40px;
}
body #home_page .faq_section .faq_content .faq_title {
  max-width: 390px;
  margin: auto;
  font-size: 44px;
  margin-bottom: 10px !important;
  line-height: 1;
}
body #home_page .faq_section .faq_content .faq_subtitle {
  max-width: 450px;
  margin: auto;
  color: grey;
}
body #home_page .faq_section .faq_card_wrapper {
  background-color: #f0f2f5;
  box-shadow: -2px -1px 4px 0px rgba(122, 121, 123, 0.75);
  -webkit-box-shadow: -2px -1px 4px 0px rgba(122, 121, 123, 0.75);
  -moz-box-shadow: -2px -1px 4px 0px rgba(122, 121, 123, 0.75);
  border-radius: 12px;
  margin: 5px auto 26px;
}
body #home_page .faq_section .faq_card_wrapper .faq_card {
  cursor: pointer;
  width: 100%;
  padding: 22px 15px;
}
body #home_page .faq_section .faq_card_wrapper .faq_card .faq_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
body #home_page .faq_section .faq_card_wrapper .faq_card .faq_header .faq_header_title {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 19px;
  color: rgb(8, 8, 8);
}
body #home_page .faq_section .faq_card_wrapper .faq_card .faq_header .faq_arrow {
  width: 32px;
}
body #home_page .faq_section .faq_card_wrapper .faq_card .faq_header .faq_arrow.open {
  transform: rotate(180deg);
}
body #home_page .faq_section .faq_card_wrapper .faq_answer {
  height: 0;
  overflow: hidden;
  z-index: -1;
  position: relative;
  opacity: 0;
  transition: 0.7s ease;
  color: #303030;
}
body #home_page .faq_section .faq_card_wrapper .faq_answer.openbox {
  display: block;
  position: relative;
  height: auto !important;
  opacity: 1 !important;
  padding: 0 15px 15px;
  z-index: 1;
}
body .shop_wrapper .header {
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
  padding: 10px 0;
  position: relative;
}
body .shop_wrapper .header .header_content {
  align-items: center;
}
body .shop_wrapper .shop {
  background-color: #f7f7f7;
  padding-top: 40px;
}
body .shop_wrapper .shop .shop_header .shop_header_title {
  padding-bottom: 25px;
}
body .shop_wrapper .shop .search_bar_container .searchBar_wrapper {
  background-color: #fff;
  border-radius: 12px;
  padding: 40px 15px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
body .shop_wrapper .shop .search_bar_container .searchBar_wrapper .search_form .searchBar {
  display: flex;
  align-items: center;
}
body .shop_wrapper .shop .search_bar_container .searchBar_wrapper .search_form .searchBar .submitBtn_div .select_input {
  width: 100%;
}
body .shop_wrapper .shop .search_bar_container .searchBar_wrapper .search_form .searchBar .submitBtn_div .submitBtn {
  color: white;
  width: 100%;
}
body .shop_wrapper .shop .search_bar_container .searchBar_wrapper .search_form .searchBar .submitBtn_div .form-control:focus {
  border-color: #7e9b78 !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(126, 155, 120, 0.25) !important;
}
body .shop_wrapper .shop .clear_btn_div {
  float: inline-end;
}
body .shop_wrapper .shop .clear_btn_div .clearBtn {
  background-color: transparent;
  color: #7e9b78;
  border: none;
  outline: none;
  margin-top: 5px;
}
body .shop_wrapper .shop .not_available_message {
  text-align: center;
  font-size: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 49vh;
}
body .shop_wrapper .shop .books_collection {
  padding: 40px 0;
}
body .shop_wrapper .shop .pagBtn {
  color: black;
}
body .shop_wrapper .shop .pagItem {
  color: black;
}
body .shop_wrapper .shop .pagItem.active .page-link {
  background-color: #7e9b78 !important;
  color: white;
}
body .shop_wrapper .shop .pagItem .page-link {
  color: black;
}
body .book_card {
  cursor: pointer;
  border-radius: 9px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px;
  background-color: #fff;
  transition: all 0.2s;
  max-width: 300px;
  margin: auto;
}
body .book_card .book_cover {
  max-width: 400px;
  height: 300px;
}
body .book_card .book_cover img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
body .book_card .book_info {
  text-align: center;
  padding-top: 20px;
}
body .book_card .book_info .book_category {
  margin-bottom: 0;
  font-weight: 600;
}
body .book_card .book_info .book_author {
  margin-bottom: 6px;
  font-weight: 600;
  color: #343a40;
}
body .book_card:hover {
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.2);
}
body .footer .top_footer {
  text-align: center;
  background: url("..//assets//images//grid2.svg"), #252c34;
  padding: 20px 0 10px;
}
body .footer .top_footer .footer_logo {
  width: 100px;
  margin: auto;
}
body .footer .top_footer .footer_logo img {
  width: 100%;
}
body .footer .top_footer .footer_title {
  color: white;
  font-size: 60px;
  max-width: 451px;
  margin: 20px auto 10px;
}
body .footer .top_footer .footer_description {
  color: #e2e7f0;
  max-width: 400px;
  margin: 20px auto 30px;
}
body .footer .top_footer .footer_btn {
  margin-bottom: 15px;
  color: white;
  padding: 9px 38px;
  font-size: 17px;
}
body .footer .top_footer .instaIcon {
  width: 32px;
  margin: 10px auto 20px;
}
body .footer .top_footer .instaIcon img {
  width: 100%;
}
body .footer .top_footer .instaIcon:hover {
  opacity: 0.8;
}
body .footer .bottom_footer {
  background-color: #1f242b;
  color: rgb(233, 233, 233);
}
body .footer .bottom_footer .copyrights_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
body .footer .bottom_footer .copyrights_section p {
  margin-bottom: 0;
}
body .footer .bottom_footer .copyrights_section .developer a {
  color: #7e9b78;
}
body .footer .bottom_footer .copyrights_section .developer a:hover {
  opacity: 0.8;
}
body .login_page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
body .login_page .form_wrapper {
  background-color: #e2e7f0;
  border-radius: 8px;
  padding: 20px 15px;
}
body .login_page .form_wrapper .login-form .submitBtn {
  width: 100%;
  color: white !important;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  body .question_section .books3d_img {
    display: none;
  }
}
@media (max-width: 376px) {
  body #home_page .line img {
    margin: auto !important;
    max-width: 300px !important;
  }
  body #home_page .hero_section .hero_section_wrapper {
    height: 65vh !important;
  }
  body #home_page .hero_section .hero_section_wrapper .hero_content .hero-title {
    font-size: 40px !important;
  }
  body #home_page .hero_section .hero_section_wrapper .hero_content .hero_description {
    font-size: 15px !important;
  }
  body #home_page .features_section .features_header .content_title {
    font-size: 35px;
    margin: 10px auto 80px;
  }
  body #home_page .features_section .features_header .content_title .underlined_word {
    left: 0% !important;
  }
  body #home_page .question_section .books3d_img {
    top: 493px !important;
  }
  body #home_page .question_section .books3d_img img {
    max-width: 370px !important;
  }
}
@media (min-width: 990px) {
  body .ourstory_section .ourstory_header {
    margin-bottom: 70px !important;
  }
  body .ourstory_section .ourstory_img {
    margin: auto;
    margin-top: 0px !important;
    max-width: 400px;
    height: 500px;
  }
  body .ourstory_section .ourstory_img img {
    width: 100%;
    height: 100%;
  }
  body .ourstory_section .ourstory_content {
    margin-top: 30px !important;
  }
  .aboutUs_section .second_fam {
    display: flex;
    flex-direction: row-reverse;
  }
  .aboutUs_section .second_fam .aboutImg {
    float: inline-end;
  }
  .aboutUs_section .aboutImg {
    float: inline-start;
    margin: auto;
    width: 400px !important;
  }
  .aboutUs_section .about_description .about_description_title {
    max-width: 420px;
  }
  .aboutUs_section .about_description .about_description_paragraph {
    max-width: 470px;
  }
}
@media (min-width: 500px) and (max-width: 989px) {
  body .features_section .features_header .content_title {
    font-size: 35px;
    margin: auto;
  }
  body .features_section .features_header .content_title .underlined_word {
    left: 5% !important;
  }
}
@media (max-width: 989px) {
  body .header_content .nav_links {
    display: none;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(to bottom, #ffffff, #f5f0ff);
    box-shadow: 3px 1px 20px 0px rgba(214, 214, 214, 0.75);
    -webkit-box-shadow: 9px 15px 20px 0px rgba(214, 214, 214, 0.75);
    -moz-box-shadow: 3px 1px 20px 0px rgba(214, 214, 214, 0.75);
    position: absolute;
    top: 100px;
    text-align: center;
    left: 0;
    right: 0;
    margin: auto;
    width: 90%;
    z-index: 1000;
    transform: translateY(-100%);
    transition: opacity 400ms ease-out, transform 400ms ease-out;
    opacity: 0;
    padding: 30px 0;
  }
  body .header_content .nav_links.show {
    display: flex;
    transition: all 800ms cubic-bezier(0.23, 1, 0.32, 1);
    transform: translateY(0);
    opacity: 1;
  }
  body .header_content .nav_links .nav_list {
    display: flex;
    flex-direction: column;
    padding-left: 0;
  }
  body .header_content .nav_links li {
    padding-right: 0 !important;
    padding-bottom: 20px;
  }
  body .header_content .nav_cta {
    display: flex;
    align-items: center;
  }
  body .header_content .nav_cta .navbtn {
    margin-right: 14px;
  }
  body #all.open {
    transform: translateX(-320px);
  }
  body #all {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }
  body .mobile_menu {
    z-index: 1000;
    display: block !important;
  }
  body .hero_section .hero_section_wrapper .hero_content .hero-title .underlined_word {
    left: 0% !important;
  }
  body .contactUs_page .contactUs_form {
    margin: 30px 10px !important;
  }
  body .contactUs_page .contactUs_info {
    margin-top: 40px !important;
  }
  body .aboutUs_section .aboutImg {
    margin: 0px auto 15px !important;
  }
  body .aboutUs_section .about_description_title {
    max-width: 400px;
    margin: 0 auto 15px !important;
  }
  body .aboutUs_section .about_description {
    text-align: center;
  }
  body .question_section .question_div .question {
    font-size: 42px !important;
  }
  body .question_section .books3d_img {
    top: 462px !important;
  }
  body .question_section .books3d_img img {
    max-width: 480px !important;
  }
  body .packages_section .package_card_wrapper {
    margin-bottom: 25px !important;
  }
  body .features_section .features {
    max-width: 500px !important;
    margin: 3px auto 15px !important;
  }
  body .howtohelp_section .left_section {
    margin-bottom: 60px !important;
  }
  body .two_lines {
    max-width: 90%;
    margin: auto;
  }
  body .two_lines img {
    width: 100%;
  }
  body .footer .top_footer .footer_title {
    font-size: 50px !important;
  }
  body .footer .bottom_footer .copyrights_section {
    text-align: center;
    display: flex;
    flex-direction: column !important;
  }
  body .footer .bottom_footer .copyrights_section .developer {
    margin-top: 15px;
  }
}
@media (min-width: 379px) and (max-width: 490px) {
  body .question_section {
    height: 60vh !important;
  }
  body .question_section .books3d_img {
    top: 400px !important;
  }
  body .question_section .books3d_img img {
    max-width: 360px !important;
  }
  body .features_section .content_title .underlined_word {
    left: 0% !important;
  }
}
@media (max-width: 786px) {
  body .introductory_vid .video_thumbnail .thumbnail img {
    height: 280px !important;
  }
}
@media (min-width: 700px) and (max-width: 990px) {
  body .howtohelp_section .left_section {
    align-items: center !important;
    text-align: center !important;
  }
  body .howtohelp_section .right_section .right_list li {
    justify-content: center !important;
  }
  body .howtohelp_section .right_section .right_title {
    margin-top: 10px;
    text-align: center;
  }
  body .howtohelp_section .right_section .right_note {
    margin: 38px auto 0 !important;
  }
}
@keyframes waves {
  0% {
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}
.waves {
  position: absolute;
  width: 150px;
  height: 150px;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  border-radius: 100%;
  z-index: -1;
  animation: waves 3s ease-in-out infinite;
}

.wave-1 {
  animation-delay: 0s;
}

.wave-2 {
  animation-delay: 1s;
}

.wave-3 {
  animation-delay: 2s;
}/*# sourceMappingURL=styles.css.map */