@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Oswald:wght@200..700&display=swap");

// .lato-regular {
//   font-family: "Lato", sans-serif;
//   font-weight: 400;
//   font-style: normal;
// }

// .lato-light {
//   font-family: "Lato", sans-serif;
//   font-weight: 300;
//   font-style: normal;
// }

// .lato-bold {
//   font-family: "Lato", sans-serif;
//   font-weight: 700;
//   font-style: normal;
// }

// .lato-black {
//   font-family: "Lato", sans-serif;
//   font-weight: 900;
//   font-style: normal;
// }
// .oswald {
//   font-family: "Oswald", sans-serif;
//   font-optical-sizing: auto;
//   font-weight: 700;
//   font-style: normal;
// }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Oswald", sans-serif;
}

p,
button,
a,
ul {
  font-family: "Lato", sans-serif;
}
ul {
  list-style: none;
  padding-left: 0;
}
a {
  text-decoration: none;
}
$dark-green: #7e9b78;
$light-green: #e2e7f0;
$dark-blue: #252c34;
$light-black: #1e1e1e;
$defaultAnimation: all 0.4s ease-in-out;

body {
  .popup {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    box-shadow: -3px -1px 20px 10000px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 500px;
    max-width: 550px;
    height: 300px;
    border-radius: 20px;
    z-index: 9999;
  }

  .popup-content {
    font-size: 25px;
    text-align: center;
  }

  .ctaBtn {
    border: none;
    padding: 7px 20px;
    border-radius: 5px;
    font-weight: 500;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $dark-green;
      z-index: -2;
    }
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: darken(rgba(255, 255, 255, 0.248), 15%);
      transition: all 0.5s;
      z-index: -1;
    }
    &:hover {
      color: #fff;
      &:before {
        width: 100%;
      }
    }
  }
  .header {
    padding: 20px 0;
    width: 100%;
    .header_content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo {
        width: 60px;
        cursor: pointer;
        img {
          width: 100%;
        }
      }
      .headerBtn {
        border-radius: 30px;
        padding: 7px 38px !important;
        background-color: $dark-green;
        color: white;
        transition: all 0.3s;
        position: relative;
        overflow: hidden;
        z-index: 1;
      }
      .nav_links {
        .nav_list {
          list-style: none;
          display: flex;
          margin-bottom: 0;
          padding-left: 0;
          .nav_link {
            text-decoration: none;
            color: #000;
            li {
              margin: 0 15px;
              &:hover {
                opacity: 0.8;
              }
            }
          }
        }
      }
      .nav_cta {
        .mobile_menu {
          display: none;
          margin-left: 10px;
          img {
            width: 32px;
          }
        }
      }
    }
  }
  .contactUs_page {
    .hero_section {
      height: 60vh !important;
      .hero_section_wrapper {
        height: 30vh !important;
      }
    }
    .contactUs_form {
      margin: 150px 50px;
      .form_wrapper {
        margin: auto;
        max-width: 450px;
        background-color: white;
        box-shadow: -2px -1px 4px 0px rgb(122 121 123 / 75%);
        -webkit-box-shadow: -2px -1px 4px 0px rgb(122 121 123 / 75%);
        -moz-box-shadow: -2px -1px 4px 0px rgb(122 121 123 / 75%);
        border-radius: 12px;
        padding: 40px 29px;
        .form {
          margin-top: 30px;
        }
        .contactus_form_title {
          color: $dark-green;
          font-size: 36px;
          margin-bottom: 20px;
        }
        .submitBtn {
          width: 100%;
          background-color: $dark-green;
          color: white;
        }
      }
      .contactUs_info {
        margin: auto;
        max-width: 500px;
        padding: 40px 29px;
        .contactusInfo_title {
          margin-bottom: 30px;
          font-size: 36px;
        }
        .chating_option {
          margin-bottom: 30px;
          .social_icon {
            max-width: 35px;
            &:hover {
              opacity: 0.8;
            }
          }
          .social_info {
            color: $dark-blue;
            margin-left: 10px;
            font-size: 18px;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
  #home_page {
    .colored_word {
      color: $dark-green;
    }
    .hero_section {
      background: url("..//assets//images//hero-grid2.svg");
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: 40px;
      height: 80vh;
      .hero_section_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 60vh;
        .hero_content {
          text-align: center;
          margin: auto;
          max-width: 800px;
          .hero-title {
            font-size: 45px;
            max-width: 590px;
            margin: 15px auto 25px;
            position: relative;
            .underlined_word {
              z-index: 1;
              width: 100%;
              max-width: none;
              display: inline-block;
              position: absolute;
              top: auto;
              bottom: 0%;
              left: 23%;
              right: 0%;
              transform: translateY(25%);
              padding-top: 30px;
              img {
                width: 210px;
              }
            }
          }
          .hero_description {
            line-height: 1.3;
            max-width: 720px;
            font-size: 18px;
            margin: 5px auto 35px;
          }
          .heroBtn {
            background-color: $dark-green;
            color: white;
            font-size: 17px;
            padding: 8px 39px;
          }
        }
      }
    }

    .introductory_vid {
      margin-bottom: 50px;
      .video_thumbnail {
        position: relative;
        .thumbnail {
          width: 100%;
          background: url("..//assets/images/playIcon.png");
          background-repeat: no-repeat;
          background-position: center;

          img {
            height: 585px;
            border-radius: 10px;
            position: relative;
            width: 100%;
          }
        }
        .video-icon {
          position: absolute;
          cursor: pointer;
          top: 50%;
          left: 50%;
          width: 80px;
          height: 80px;
          border-radius: 100%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 8;
          padding: 20px;
          opacity: 1;
          img {
            width: 81px;
          }
        }
        .waves-block {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .ourstory_section {
      margin-top: 80px;
      .ourstory_header {
        margin: auto;
        text-align: center;
        max-width: 300px;
        .section_title {
          font-size: 37px;
        }
        .subtitle {
          margin-bottom: 0;
        }
        img {
          width: 90%;
        }
        margin-bottom: 40px;
      }
      .ourstory_content {
        .content_title {
          margin-bottom: 30px;
        }
        .ourstory_list {
          margin-top: 20px;
          li {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            p {
              padding-left: 6px;
              margin-bottom: 0;
            }
          }
        }
      }
      .ourstory_img {
        width: 100%;
        margin-top: 40px;
        img {
          width: 100%;
          border-radius: 10px;
        }
      }
    }

    .aboutUs_section {
      margin-top: 80px;
      padding: 50px 0;
      background-color: $light-green;
      .aboutUs_header {
        text-align: center;
        max-width: 400px;
        margin: 10px auto 60px;
        .content_title {
          font-size: 37px;
        }
        img {
          width: 90%;
        }
      }

      .aboutImg {
        margin-bottom: 15px;
        width: 300px;
        &.aboutimg1 {
          background-image: url("..//assets//images//IMG_9653.jpg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          width: 300px;
          height: 250px;
          border-radius: 12px;
        }
        &.aboutimg2 {
          background-image: url("..//assets//images//library6.jpeg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          width: 300px;
          height: 250px;
          border-radius: 12px;
        }
        &.aboutimg3 {
          background-image: url("..//assets//images//library1.jpeg");
          background-repeat: no-repeat;
          background-position: top;
          background-size: cover;
          width: 300px;
          height: 250px;
          border-radius: 12px;
        }
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
      .about_description {
        margin-top: 20px;
        .about_subtitle {
          margin-bottom: 0;
        }
        .about_description_title {
          margin-bottom: 20px;
        }
        .about_description_paragraph {
          line-height: 1.3;
        }
      }
    }
    .line {
      text-align: center;
      margin: 30px auto 50px;
    }

    .question_section {
      background: url("..//assets//images//grid2.svg"), $dark-green;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      height: 90vh;
      .question_div {
        .question {
          color: white;
          font-size: 60px;
          text-align: center;
          margin: 0 auto 70px;
          max-width: 729px;
        }
      }
      .books3d_img {
        position: absolute;
        top: 416px;
        img {
          max-width: 690px;
        }
      }
    }
    .packages_section {
      margin-top: 153px;
      margin-bottom: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .packages_header {
        text-align: center;
        max-width: 400px;
        margin: 10px auto 70px;
        .content-title {
          font-size: 37px;
        }
        .subtitle {
          margin-bottom: 0;
        }
        img {
          width: 90%;
        }
      }
      .package_card_wrapper {
        max-width: 330px;
        margin: 5px auto 10px;
        border-radius: 12px;
        padding: 25px 20px;
        box-shadow: -2px -1px 4px 0px rgb(122 121 123 / 75%);
        -webkit-box-shadow: -2px -1px 4px 0px rgb(122 121 123 / 75%);
        -moz-box-shadow: -2px -1px 4px 0px rgb(122 121 123 / 75%);
        &.reader {
          margin-top: 30px;
          background-color: $light-green;
          .popular {
            display: none;
          }
          .packages_card_footer {
            .package_btn {
              background-color: $dark-green !important;
              color: white;
            }
          }
        }
        &.bookworm {
          background-color: $dark-green;
          color: white;
          .popular {
            p {
              color: black;
              margin-bottom: 0 !important;
            }
            background-color: #e2e7f0;
            border-radius: 50px;
            padding: 4px 15px;
            float: right;
          }
          .package_btn {
            color: black;
            &:after {
              content: "";
              background-color: $light-green;
            }
            &:before {
              content: "";
              background-color: darken(rgba(132, 167, 125, 0.2), 15%);
            }
            &:hover {
              color: #1f242b;
              &:before {
                width: 100%;
              }
            }
          }
        }
        .package_card_header {
          .package_price {
            font-size: 19px;
            margin-bottom: 29px;
            .price {
              font-weight: bold;
            }
          }
        }
        .packages_card_footer {
          .package_btn {
            margin-top: 10px;
            width: 100% !important;
            text-align: center;
            padding: 9px 20px;
            font-size: 17px;
          }
        }
        .package_line {
          height: 1px;
          margin: 17px 0;
          width: 100%;
          background-color: #252c34;
        }
      }
    }

    .features_section {
      background-color: $light-green;
      padding: 60px 0 80px;
      .content_title {
        position: relative;
        text-align: center;
        max-width: 400px;
        font-size: 37px;
        margin: 10px auto 80px;
        .underlined_word {
          z-index: 1;
          width: 100%;
          max-width: none;
          display: inline-block;
          position: absolute;
          top: auto;
          bottom: 0%;
          left: 5%;
          right: 0%;
          transform: translateY(25%);
          padding-top: 30px;
          img {
            width: 160px;
          }
        }
      }
      .features {
        background-color: white;
        border-radius: 13px;
        padding: 25px 15px;
        margin-bottom: 10px;
        box-shadow: -2px -1px 4px 0px rgb(122 121 123 / 75%);
        -webkit-box-shadow: -2px -1px 4px 0px rgb(122 121 123 / 75%);
        -moz-box-shadow: -2px -1px 4px 0px rgb(122 121 123 / 75%);
        transition: $defaultAnimation;
        cursor: pointer;
        &:hover {
          box-shadow: 0 0px 2px 2px rgba(0, 0, 0, 0.1),
            0 6px 9px rgba(0, 0, 0, 0.2);
          z-index: 1;
        }
        .features_card {
          .features_img {
            width: 85px;
            height: 80px;

            margin-bottom: 20px;
            &.wifi {
              height: 59px !important;
              width: 73px !important;
            }

            img {
              width: 100%;
              height: 100%;
            }
          }
          .feature_card_content {
            .card_title {
              color: $dark-green;
              margin-bottom: 15px;
              font-size: 21px;
            }
            .card_description {
              line-height: 1.3;
            }
          }
        }
      }
    }
    .howtohelp_section {
      padding: 100px 0;
      .left_section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .left_title {
          margin-bottom: 45px;
          position: relative;
          font-size: 40px;
          max-width: 400px;
          .underlined_word {
            z-index: 1;
            width: 100%;
            max-width: none;
            display: inline-block;
            position: absolute;
            top: auto;
            bottom: 0%;
            left: 0%;
            right: 0%;
            transform: translateY(25%);
            padding-top: 34px;
            img {
              width: 221px;
            }
          }
        }
        .left_description {
          max-width: 400px;
          font-size: 18px;
          margin-bottom: 35px;
        }
        .donateBtn {
          background-color: $dark-green;
          color: white;
        }
      }
      .right_section {
        .right_title {
          font-size: 23px;
          margin-bottom: 35px;
        }
        .right_list {
          margin-top: 25px;
          li {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            p {
              font-size: 16px;
              margin-left: 10px;
              margin-bottom: 0 !important;
            }
          }
        }
        .right_note {
          max-width: 430px;
          margin-top: 38px;
          font-weight: bold;
          .red {
            color: red;
          }
        }
      }
    }
    .two_lines {
      text-align: center;
      img {
        width: 90%;
        margin: auto;
      }
    }

    .faq_section {
      padding: 70px 0;
      .faq_content {
        text-align: center;
        color: $dark-blue;
        margin-bottom: 40px;
        .faq_title {
          max-width: 390px;
          margin: auto;
          font-size: 44px;
          margin-bottom: 10px !important;
          line-height: 1;
        }
        .faq_subtitle {
          max-width: 450px;
          margin: auto;
          color: grey;
        }
      }
      .faq_card_wrapper {
        background-color: #f0f2f5;
        box-shadow: -2px -1px 4px 0px rgb(122 121 123 / 75%);
        -webkit-box-shadow: -2px -1px 4px 0px rgb(122 121 123 / 75%);
        -moz-box-shadow: -2px -1px 4px 0px rgb(122 121 123 / 75%);
        border-radius: 12px;
        margin: 5px auto 26px;
        .faq_card {
          cursor: pointer;
          width: 100%;
          padding: 22px 15px;

          .faq_header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .faq_header_title {
              margin-bottom: 0;
              font-weight: 400;
              font-size: 19px;
              color: rgb(8, 8, 8);
            }

            .faq_arrow {
              width: 32px;

              &.open {
                transform: rotate(180deg);
              }
            }
          }
        }

        .faq_answer {
          height: 0;
          overflow: hidden;
          z-index: -1;
          position: relative;
          opacity: 0;
          -webkit-transition: 0.7s ease;
          -moz-transition: 0.7s ease;
          -o-transition: 0.7s ease;
          transition: 0.7s ease;
          color: #303030;
          &.openbox {
            display: block;
            position: relative;
            height: auto !important;
            opacity: 1 !important;
            padding: 0 15px 15px;
            z-index: 1;
          }
        }
      }
    }
  }
  .shop_wrapper {
    .header {
      box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
      padding: 10px 0;
      position: relative;
      .header_content {
        align-items: center;
      }
    }
    .shop {
      background-color: #f7f7f7;
      padding-top: 40px;
      .shop_header {
        .shop_header_title {
          padding-bottom: 25px;
        }
      }
      .search_bar_container {
        .searchBar_wrapper {
          background-color: #fff;
          border-radius: 12px;
          padding: 40px 15px;
          border: 1px solid rgba(0, 0, 0, 0.125);
          .search_form {
            .searchBar {
              display: flex;
              align-items: center;
              .submitBtn_div {
                .select_input {
                  width: 100%;
                }
                .submitBtn {
                  color: white;
                  width: 100%;
                }
                .form-control {
                  &:focus {
                    border-color: $dark-green !important;
                    outline: 0;
                    box-shadow: 0 0 0 0.25rem rgb(126 155 120 / 25%) !important;
                  }
                }
              }
            }
          }
        }
      }
      .clear_btn_div {
        float: inline-end;
        .clearBtn {
          background-color: transparent;
          color: $dark-green;
          border: none;
          outline: none;
          margin-top: 5px;
        }
      }
      .not_available_message {
        text-align: center;
        font-size: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 49vh;
      }
      .books_collection {
        padding: 40px 0;
      }
      .pagBtn {
        color: black;
      }
      .pagItem {
        color: black;
        &.active {
          .page-link {
            background-color: #7e9b78 !important;
            color: white;
          }
        }
        .page-link {
          color: black;
        }
      }
    }
  }
  .book_card {
    cursor: pointer;
    border-radius: 9px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 10px;
    background-color: #fff;
    transition: all 0.2s;
    max-width: 300px;
    margin: auto;
    .book_cover {
      max-width: 400px;
      height: 300px;
      img {
        width: 100%;
        height: 100%;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    }
    .book_info {
      text-align: center;
      padding-top: 20px;
      .book_category {
        margin-bottom: 0;
        font-weight: 600;
      }
      .book_author {
        margin-bottom: 6px;
        font-weight: 600;
        color: #343a40;
      }
    }
    &:hover {
      box-shadow: 3px 3px 10px 3px rgb(0 0 0 / 20%);
    }
  }
  .footer {
    .top_footer {
      text-align: center;
      // background-color: #252c34;
      background: url("..//assets//images//grid2.svg"), #252c34;
      padding: 20px 0 10px;
      .footer_logo {
        width: 100px;
        margin: auto;
        img {
          width: 100%;
        }
      }
      .footer_title {
        color: white;
        font-size: 60px;
        max-width: 451px;
        margin: 20px auto 10px;
      }
      .footer_description {
        color: #e2e7f0;
        max-width: 400px;
        margin: 20px auto 30px;
      }
      .footer_btn {
        margin-bottom: 15px;
        color: white;
        padding: 9px 38px;
        font-size: 17px;
      }

      .instaIcon {
        width: 32px;
        margin: 10px auto 20px;
        img {
          width: 100%;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .bottom_footer {
      background-color: #1f242b;
      color: rgb(233, 233, 233);
      .copyrights_section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          margin-bottom: 0;
        }
        padding: 10px 0;
        .developer {
          a {
            color: $dark-green;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
  .login_page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .form_wrapper {
      background-color: $light-green;
      border-radius: 8px;
      padding: 20px 15px;
      .login-form {
        .submitBtn {
          width: 100%;
          color: white !important;
        }
      }
    }
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  body {
    .question_section {
      .books3d_img {
        display: none;
      }
    }
  }
}

@media (max-width: 376px) {
  body {
    #home_page {
      .line {
        img {
          margin: auto !important;
          max-width: 300px !important;
        }
      }
      .hero_section {
        .hero_section_wrapper {
          height: 65vh !important;
          .hero_content {
            .hero-title {
              font-size: 40px !important;
            }
            .hero_description {
              font-size: 15px !important;
            }
          }
        }
      }
      .features_section {
        .features_header {
          .content_title {
            font-size: 35px;
            margin: 10px auto 80px;
            .underlined_word {
              left: 0% !important;
            }
          }
        }
      }
      .question_section {
        .books3d_img {
          top: 493px !important;
          img {
            max-width: 370px !important;
          }
        }
      }
    }
  }
}
@media (min-width: 990px) {
  body {
    .ourstory_section {
      .ourstory_header {
        margin-bottom: 70px !important;
      }
      .ourstory_img {
        margin: auto;
        margin-top: 0px !important;
        max-width: 400px;
        height: 500px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .ourstory_content {
        margin-top: 30px !important;
      }
    }
  }

  .aboutUs_section {
    .second_fam {
      display: flex;
      flex-direction: row-reverse;
      .aboutImg {
        float: inline-end;
      }
    }
    .aboutImg {
      float: inline-start;
      margin: auto;
      width: 400px !important;
    }
    .about_description {
      .about_description_title {
        max-width: 420px;
      }
      .about_description_paragraph {
        max-width: 470px;
      }
    }
  }
}

@media (min-width: 500px) and (max-width: 989px) {
  body {
    .features_section {
      .features_header {
        .content_title {
          font-size: 35px;
          margin: auto;
          .underlined_word {
            left: 5% !important;
          }
        }
      }
    }
  }
}
@media (max-width: 989px) {
  body {
    .header_content {
      .nav_links {
        display: none;
        flex-direction: column;
        align-items: center;
        background: linear-gradient(to bottom, #ffffff, #f5f0ff);
        box-shadow: 3px 1px 20px 0px rgba(214, 214, 214, 0.75);
        -webkit-box-shadow: 9px 15px 20px 0px rgba(214, 214, 214, 0.75);
        -moz-box-shadow: 3px 1px 20px 0px rgba(214, 214, 214, 0.75);
        position: absolute;
        top: 100px;
        text-align: center;
        left: 0;
        right: 0;
        margin: auto;
        width: 90%;
        z-index: 1000;
        transform: translateY(-100%);
        transition: opacity 400ms ease-out, transform 400ms ease-out;
        opacity: 0;
        padding: 30px 0;

        &.show {
          display: flex;
          transition: all 800ms cubic-bezier(0.23, 1, 0.32, 1);
          transform: translateY(0);
          opacity: 1;
        }
        .nav_list {
          display: flex;
          flex-direction: column;
          padding-left: 0;
        }
        li {
          padding-right: 0 !important;
          padding-bottom: 20px;
        }
      }

      .nav_cta {
        display: flex;
        align-items: center;
        .navbtn {
          margin-right: 14px;
        }
      }
    }
    #all.open {
      transform: translateX(-320px);
    }

    #all {
      transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
    }
    .mobile_menu {
      z-index: 1000;
      display: block !important;
    }

    .hero_section {
      .hero_section_wrapper {
        .hero_content {
          .hero-title {
            .underlined_word {
              left: 0% !important;
            }
          }
        }
      }
    }
    .contactUs_page {
      .contactUs_form {
        margin: 30px 10px !important;
      }
      .contactUs_info {
        margin-top: 40px !important;
      }
    }
    .aboutUs_section {
      .aboutImg {
        margin: 0px auto 15px !important;
      }
      .about_description_title {
        max-width: 400px;
        margin: 0 auto 15px !important;
      }
      .about_description {
        text-align: center;
      }
    }
    .question_section {
      .question_div {
        .question {
          font-size: 42px !important;
        }
      }
      .books3d_img {
        top: 462px !important;
        img {
          max-width: 480px !important;
        }
      }
    }
    .packages_section {
      .package_card_wrapper {
        margin-bottom: 25px !important;
      }
    }
    .features_section {
      .features {
        max-width: 500px !important;
        margin: 3px auto 15px !important;
      }
    }
    .howtohelp_section {
      .left_section {
        margin-bottom: 60px !important;
      }
    }
    .two_lines {
      max-width: 90%;
      margin: auto;
      img {
        width: 100%;
      }
    }
    .footer {
      .top_footer {
        .footer_title {
          font-size: 50px !important;
        }
      }
      .bottom_footer {
        .copyrights_section {
          text-align: center;
          display: flex;
          flex-direction: column !important;
          .developer {
            margin-top: 15px;
          }
        }
      }
    }
  }
}
@media (min-width: 379px) and (max-width: 490px) {
  body {
    .question_section {
      height: 60vh !important;
      .books3d_img {
        top: 400px !important;
        img {
          max-width: 360px !important;
        }
      }
    }
    .features_section {
      .content_title {
        .underlined_word {
          left: 0% !important;
        }
      }
    }
  }
}
@media (max-width: 786px) {
  body {
    .introductory_vid {
      .video_thumbnail {
        .thumbnail {
          img {
            height: 280px !important;
          }
        }
      }
    }
  }
}
@media (min-width: 700px) and (max-width: 990px) {
  body {
    .howtohelp_section {
      .left_section {
        align-items: center !important;
        text-align: center !important;
      }
      .right_section {
        .right_list {
          li {
            justify-content: center !important;
          }
        }
        .right_title {
          margin-top: 10px;
          text-align: center;
        }
        .right_note {
          margin: 38px auto 0 !important;
        }
      }
    }
  }
}
@keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

.waves {
  position: absolute;
  width: 150px;
  height: 150px;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  border-radius: 100%;
  z-index: -1;
  -webkit-animation: waves 3s ease-in-out infinite;
  animation: waves 3s ease-in-out infinite;
}

.wave-1 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.wave-2 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.wave-3 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
